<template>
  <div>
    <top-nav-bar></top-nav-bar>
    <div class="page position-absolute">
      <section class="container">
        <div class="my-2" v-html="markdown"></div>
      </section>
    </div>
  </div>
</template>

<script>
import marked from "marked";
import impressum from "./impressum.md";
export default {
  inject: ["activePage"],
  computed: {
    markdown() {
      return marked(impressum);
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeImpressumPage;
  },
};
</script>

<style scoped></style>
